import Http from './http';
import { IPage } from '@/types/api/common';
import {
  IStatisticsDayAndTypeRequest,
  IStatisticsDayAndTypeDataRequest,
  IStatisticsByDayAndTypeResponse,
  IMaterialStatistics,
} from '@/types/api/statistics';

export default class Statistics {
  /**
   * 按天和类型统计数量
   * @param data
   * @returns
   */
  static byDayAndType(
    data: IStatisticsDayAndTypeDataRequest,
  ): Promise<IPage<IStatisticsByDayAndTypeResponse>> {
    const obj = {
      mediaDayAndTypeStatisticsQueryDTO: data,
    } as IStatisticsDayAndTypeRequest;
    return Http.post('/m.api/admin.statistics/statisticsByDayAndType', obj);
  }

  /**
   * 按类型统计数量
   * @param data
   * @returns
   */
  static byType(data: any): Promise<IMaterialStatistics> {
    const obj = {
      mediaTypeStatisticsQueryDTO: data,
    };
    return Http.post('/m.api/admin.statistics/statisticsByType', obj);
  }

  /**
   * 统计素材数量
   * @param data
   * @returns
   */
  static mediaCountStatistics(data: any): Promise<number> {
    const obj = {
      mediaCountQueryDTO: data,
    };
    return Http.post('/m.api/admin.statistics/mediaCountStatistics', obj);
  }

  /**
   * 统计素材数量
   * @param data
   * @returns
   */
  static mediaCountStatistics1(data: any): Promise<number> {
    const obj = {
      mediaCountQueryDTO: data,
    };
    return Http.post('/m.api/admin.statistics/mediaCountStatistics', obj, {
      // @ts-ignore 检测到未登录，不进行任何处理
      notAuthLogin: true,
      // @ts-ignore 检测到返回错误信息，不处理
      hideErrorMsg: true,
    });
  }

  /**
   * 统计文件夹数量
   * @param data
   * @returns
   */
  static folderCountStatistics(data: any): Promise<number> {
    const obj = {
      folderCountQueryDTO: data,
    };
    return Http.post('/m.api/admin.statistics/folderCountStatistics', obj);
  }

  /**
   * 统计文件夹数量
   * @param data
   * @returns
   */
  static folderCountStatistics1(data: any): Promise<number> {
    const obj = {
      folderCountQueryDTO: data,
    };
    return Http.post('/m.api/admin.statistics/folderCountStatistics', obj, {
      // @ts-ignore 检测到未登录，不进行任何处理
      notAuthLogin: true,
      // @ts-ignore 检测到返回错误信息，不处理
      hideErrorMsg: true,
    });
  }
}
