export default {
  systemTitle: 'PVH DAM ADMIN',
  login: {
    username: 'Username',
    password: 'Password',
    login: 'Login',
    usernamePlaceholder: 'Please enter username',
    passwordPlaceholder: 'Please enter password',
    usernameOrPasswordError: 'Username or password error',
  }
}
