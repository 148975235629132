import { createI18n } from 'vue-i18n';
import storage from '@/utils/storage';
import en from './en';
import zh from './zh';

const messages = {
  en,
  zh,
};

export const languageList: Record<string, Record<string, any>> = {
  zh: {
    current: '中 文',
    currentDate: 'zh-cn',
    changeValue: 'en',
    changeLabel: 'English',
  },
  en: {
    current: 'English',
    currentDate: 'en',
    changeValue: 'zh',
    changeLabel: '中 文',
  },
};

// 获取浏览器的语言
const language = (navigator.language || 'zh').toLocaleLowerCase();

const i18n = createI18n({
  // 如果要支持compositionAPI，此项必须设置为false
  legacy: false,
  // 优先取上次设置的语言，如果没有就取浏览器的语言
  locale: storage.getItem('lang') || language.split('-')[0] || 'zh',
  // 设置备用语言
  fallbackLocale: 'zh',
  messages,
});

export default i18n;
