import { defineStore, StoreDefinition } from 'pinia';

interface IMenuStatistics {
  // 素材组统计
  materialGroup: number;
  // 图片
  materialPicture: number;
  // 视频
  materialVideo: number;
  // 文档
  materialOffice: number;
  // 我审批的
  approvalMy: number;
  // 待审批的
  approvalDoing: number;
  // 我的任务
  taskMy: number;
  // 指派任务
  taskIssue: number;
  // 消息
  message: number;
}

export const useMenuStatisticsStore: StoreDefinition<string, IMenuStatistics> = defineStore(
  'menu-statistics',
  {
    state: (): IMenuStatistics => {
      return {
        materialGroup: 0,
        materialPicture: 0,
        materialVideo: 0,
        materialOffice: 0,
        approvalMy: 0,
        approvalDoing: 0,
        taskMy: 0,
        taskIssue: 0,
        message: 0,
      };
    },
    getters: {
      getAll(state: IMenuStatistics): IMenuStatistics {
        return state;
      },
      getMessage(state: IMenuStatistics): number {
        return state.message;
      },
    },
    actions: {
      setOne(field: keyof IMenuStatistics, count: number): void {
        this[field] = count;
      },
      setMessage(count: number) {
        this.message = count;
      },
    },
    persist: {
      enabled: true,
    },
  },
);
