import Http from './http';
import { apiPermission } from '@/decorator/api-permission';
import { IId, IPage } from '@/types/api/common';
import {
  IApprovalRequest,
  IApprovalResponse,
  IApprovalSignalRequest,
  IImportReason,
  IIdList,
  IMaterialInfo,
  IDetail,
  ILogListItem,
} from '@/types/api/media-draft';

export default class MediaDraft {
  /**
   * Media草稿审批-批量
   * @returns
   */
  @apiPermission(['media:media_draft:approve'], [])
  static approveBatch(data: IApprovalRequest): Promise<IApprovalResponse[]> {
    return Http.post('/m.api/admin.media_draft/approveBatch', data);
  }

  /**
   * Media草稿审批-单个
   * @returns
   */
  @apiPermission(['media:media_draft:approve'], '')
  static approveSingle(data: IApprovalSignalRequest): Promise<string> {
    return Http.post('/m.api/admin.media_draft/approveSingle', data);
  }

  /**
   * 上传Media草稿,PS:一个用户同一时间只能上传一个文件
   * @param {FormData} data
   * @param {File} data.file 上传文件
   * @param {string} data.filename 文件名,包括文件后缀
   * @param {string} data.folderKey 文件目录路径,如/root/张三的图片
   * @param {number} data.taskId 任务ID
   * @returns
   */
  @apiPermission(['media:media_draft:create'], {})
  static create(data: FormData): Promise<Object> {
    return Http.postForm('/m.api/admin.media_draft/create', data);
  }

  /**
   * 批量上传Media草稿
   * @param data
   * @returns
   */
  @apiPermission(['media:media_draft:create'], '')
  static createBatchByKey(data: any): Promise<string> {
    return Http.post('/m.api/admin.media_draft/createBatchByKey', data);
  }

  /**
   * 修改Media草稿-单个
   * @returns
   */
  @apiPermission(['media:media_draft:edit'], '')
  static editSingle(data: FormData): Promise<string> {
    return Http.postForm('/m.api/admin.media_draft/editSingle', data);
  }

  /**
   * 修改Media草稿数据-文件批量导入
   * @param {FormData} data
   * @param {File} data.file 上传文件
   * @returns
   */
  @apiPermission(['media:media_draft:edit'], {})
  static importTemplateData(data: FormData): Promise<IImportReason[]> {
    return Http.postForm('/m.api/admin.media_draft/importTemplateData', data);
  }

  /**
   * Media提交草稿批量撤回-批量
   * @returns
   */
  @apiPermission(['media:media_draft:revocation'], [])
  static revocationBatch(data: IIdList): Promise<IApprovalResponse[]> {
    return Http.post('/m.api/admin.media_draft/revocationBatch', data);
  }

  /**
   * Media提交草稿撤回-单个
   * @returns
   */
  @apiPermission(['media:media_draft:revocation'], '')
  static revocationSingle(data: IId): Promise<string> {
    return Http.post('/m.api/admin.media_draft/revocationSingle', data);
  }

  /**
   * Media草稿提交审批-批量
   * @returns
   */
  @apiPermission(['media:media_draft:submit'], [])
  static submitBatch(data: IIdList): Promise<IApprovalResponse[]> {
    return Http.post('/m.api/admin.media_draft/submitBatch', data);
  }

  /**
   * Media草稿提交审批-单个
   * @returns
   */
  @apiPermission(['media:media_draft:submit'], '')
  static submitSingle(data: IId): Promise<string> {
    return Http.post('/m.api/admin.media_draft/submitSingle', data);
  }

  /**
   * Media草稿模板下载
   * @returns
   */
  @apiPermission(['media:media_draft:edit'], null)
  static templateDown(data?: IIdList): Promise<IMaterialInfo[]> {
    return Http.post('/m.api/admin.media_draft/templateDown', data, {
      responseType: 'blob',
    });
  }

  /**
   * 查看自己Media草稿
   * @returns
   */
  @apiPermission(['media:media_draft:self_list'], {})
  static draftSelfList(data: any): Promise<IPage<any>> {
    return Http.post('/m.api/admin.media_draft/draftSelfList', data);
  }

  /**
   * 查看自己Media草稿
   * @returns
   */
  @apiPermission(['media:media_draft:self_list'], {})
  static draftSelfList1(data: any): Promise<IPage<any>> {
    return Http.post('/m.api/admin.media_draft/draftSelfList', data, {
      // @ts-ignore 检测到未登录，不进行任何处理
      notAuthLogin: true,
      // @ts-ignore 检测到返回错误信息，不处理
      hideErrorMsg: true,
    });
  }

  /**
   * 查询用户能审批 Media草稿 提交
   * @returns
   */
  @apiPermission(['media:media_draft:approve_list'], {})
  static canApproveList(data: any): Promise<IPage<any>> {
    return Http.post('/m.api/admin.media_draft/canApproveList', data);
  }

  /**
   * 查询用户能审批 Media草稿 提交
   * @returns
   */
  @apiPermission(['media:media_draft:approve_list'], {})
  static canApproveList1(data: any): Promise<IPage<any>> {
    return Http.post('/m.api/admin.media_draft/canApproveList', data, {
      // @ts-ignore 检测到未登录，不进行任何处理
      notAuthLogin: true,
      // @ts-ignore 检测到返回错误信息，不处理
      hideErrorMsg: true,
    });
  }

  /**
   * 查看自己Media草稿-按文件夹归并
   * @returns
   */
  @apiPermission(['media:media_draft:self_list'], [])
  static draftSelfListGroupByFolder(data: any): Promise<any> {
    return Http.post('/m.api/admin.media_draft/draftSelfListGroupByFolder', data);
  }

  /* 移入回收站(待审批不支持)-批量
   * @returns
   */
  @apiPermission(['media:media_draft:recycle'], [])
  static recycleBatch(data: any): Promise<any> {
    return Http.post('/m.api/admin.media_draft/recycleBatch', data);
  }

  /* 关联素材
   * @returns
   */
  @apiPermission(['media:media_draft:edit'], '')
  static updateTemplateData(data: any): Promise<any> {
    return Http.post('/m.api/admin.media_draft/updateTemplateData', data);
  }
  /* 详情
   * @returns
   */
  static detail(data: any): Promise<IDetail> {
    return Http.post('/m.api/admin.media_draft/detail', data);
  }

  /* Media草稿日志
   * @returns
   */
  @apiPermission(['media:media_draft:log_list'], [])
  static getLogById(data: any): Promise<ILogListItem[]> {
    return Http.post('/m.api/admin.media_draft/getLogById', data);
  }
  /* 回收站列表
   * @returns
   */
  @apiPermission(['media:media_draft:recycle_list'], {})
  static recycleList(data: any): Promise<any> {
    return Http.post('/m.api/admin.media_draft/recycleList', data);
  }

  /* 回收站还原-批量
   * @returns
   */
  @apiPermission(['media:media_draft:recover'], [])
  static recoverBatch(data: any): Promise<any> {
    return Http.post('/m.api/admin.media_draft/recoverBatch', data);
  }

  /* 回收站删除-批量
   * @returns
   */
  @apiPermission(['media:media_draft:delete'], [])
  static deleteBatch(data: any): Promise<any[]> {
    return Http.post('/m.api/admin.media_draft/deleteBatch', data);
  }
  /**
   * 移入回收站(待审批不支持)-单个
   * @param data
   * @returns
   */
  @apiPermission(['media:media_draft:recycle'], '')
  static recycleSingle(data: IId): Promise<string> {
    return Http.post('/m.api/admin.media_draft/recycleSingle', data);
  }

  /**
   * Media草稿删除-单个
   * @param data
   * @returns
   */
  @apiPermission(['media:media_draft:delete'], '')
  static deleteSingle(data: IId): Promise<string> {
    return Http.post('/m.api/admin.media_draft/deleteSingle', data);
  }

  /**
   * 修改Media草稿-单个（边缘上传）
   * @param data
   * @returns
   */
  @apiPermission(['media:media_draft:edit'], '')
  static editSingleKey(data: any): Promise<string> {
    return Http.post('/m.api/admin.media_draft/editSingleKey', data);
  }

  /**
   * 审核趋势
   * @returns
   */
  @apiPermission(['media:media_draft:approve_trend'], [])
  static approveTrend(): Promise<any[]> {
    return Http.post('/m.api/admin.media_draft/approveTrend');
  }

  /**
   * 导出素材
   * @returns
   */
  @apiPermission(['media:media_draft:export'], null)
  static exportMediaDraft(data: any): Promise<any> {
    return Http.post('/m.api/admin.media_draft/exportMediaDraft', data);
  }
}
