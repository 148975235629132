import Http from './http';
import { apiPermission } from '@/decorator/api-permission';
import { IPage } from '@/types/api/common';

export default class Tag {
  /**
   * 创建标签
   * @returns
   */
  static create(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/create', data);
  }

  /**
   * 删除标签
   * @returns
   */
  static delete(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/delete', data);
  }

  /**
   * 查询标签详情
   * @returns
   */
  @apiPermission(['tag:tag:detail'], {})
  static detail(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/detail', data);
  }

  /**
   * 编辑标签
   * @returns
   */
  static edit(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/edit', data);
  }

  /**
   * 查询标签列表
   * @returns
   */
  @apiPermission(['tag:tag:list'], {})
  static list(data: any): Promise<IPage<any>> {
    return Http.post('/m.api/admin.tag/list', data);
  }

  /**
   * 删除个人标签
   * @returns
   */
  @apiPermission(['tag:tag:deletePersonalTag'], '')
  static deletePersonalTag(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/deletePersonalTag', data);
  }

  /**
   * 删除企业标签
   * @returns
   */
  @apiPermission(['tag:tag:deleteEnterpriseTag'], '')
  static deleteEnterpriseTag(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/deleteEnterpriseTag', data);
  }

  /**
   * 创建个人标签
   * @returns
   */
  @apiPermission(['tag:tag:createPersonalTag'], '')
  static createPersonalTag(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/createPersonalTag', data);
  }

  /**
   * 创建企业标签
   * @returns
   */
  @apiPermission(['tag:tag:createEnterpriseTag'], '')
  static createEnterpriseTag(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/createEnterpriseTag', data);
  }

  /**
   * 下载导入模板
   * @returns
   */
  static downloadImportTemplate(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/downloadImportTemplate', data, {
      responseType: 'blob',
    });
  }

  /**
   * 标签导出
   * @returns
   */
  @apiPermission(['tag:tag:downloadTags'], [])
  static downloadTags(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/downloadTags', data, {
      responseType: 'blob',
    });
  }
  /**
   * 查询所有标签
   * @returns
   */
  @apiPermission(['tag:tag:list'], [])
  static all(data: any): Promise<any> {
    return Http.post('/m.api/admin.tag/all', data, {});
  }
}
