export default class Storage {
  // 自定义 key 前缀
  static pathPrev: string = 'pvh-dam-admin-kMeJZMZo_';

  // 设置数据
  static setItem(key: string, value: any): void {
    localStorage.setItem(Storage.pathPrev + key, JSON.stringify(value));
  }

  // 获取数据
  static getItem(key: string): any {
    const value = localStorage.getItem(Storage.pathPrev + key);
    return value ? JSON.parse(value) : null;
  }

  // 删除数据
  static removeItem(key: string): void {
    localStorage.removeItem(Storage.pathPrev + key);
  }

  // 清空所有数据
  static clear(): void {
    localStorage.clear();
  }
}
