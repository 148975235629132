import Http from './http';
import { apiPermission } from '@/decorator/api-permission';
import { IPage } from '@/types/api/common';

export default class OperationLog {
  /**
   * @param data
   * @returns
   */
  @apiPermission(['sys:operation_log:list'], {})
  static list(data: {}): Promise<IPage<any>> {
    return Http.post('/m.api/admin.operation_log/list', data);
  }
}
