import { useAuthStore } from '@/store/modules/auth';
import router from '@/router/index';

/**
 * @description: 退出登录
 */
export function useLogoutState() {
  /**
   * @description: 清除token
   */
  function clearToken() {
    const authStore = useAuthStore();
    authStore.setToken('');
    authStore.setUser();
  }

  /**
   * @description: 清除路由
   */
  function clearRoutes() {
    router.hasRoute('root') && router.removeRoute('root');
    router.hasRoute('404') && router.removeRoute('404');
  }

  /**
   * 重定向到登录页
   */
  function redirectToLogin() {
    router.replace({ path: '/login' });
  }

  function logout(): void {
    clearToken();
    clearRoutes();
    redirectToLogin();
  }

  return { logout };
}
