<template>
  <svg :class="svgClass" :style="svgStyle" aria-hidden="true">
    <use :xlink:href="iconClassName" :fill="props.color" />
  </svg>
</template>

<script lang="ts">
export default { name: 'Iconfont' };
</script>
<script setup lang="ts">
const props = defineProps({
  iconName: {
    type: String,
    required: true,
    default: '',
  },
  className: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    default: '#000000',
  },
  size: {
    type: [Number, String],
    default: '',
  },
});

// 图标在iconfont中的名字
const iconClassName = computed(() => `#${props.iconName}`);

// 给图标添加上类名
const svgClass = computed(() => {
  if (props.className) {
    return `iconfont ${props.className}`;
  } else {
    return 'iconfont';
  }
});

// 设置图标大小
const svgStyle = computed(() => {
  if (props.size) {
    return {
      width: props.size + 'px',
      height: props.size + 'px',
    };
  }

  return '';
});
</script>

<style scoped lang="scss">
.iconfont {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  position: relative;
}
</style>
