import Http from './http';
import { apiPermission } from '@/decorator/api-permission';
import { IFileResponse } from '@/types/api/file';
import { IPage } from '@/types/api/common';
import { IUuid, IPercentInitBatchDownload } from '@/types/api/file';
import { FileMargin } from '@/types/common';

export default class File {
  /**
   * 上传文件,一个用户同一时间只能上传一个文件
   * @param {FormData} data
   * @param {File} data.file 文件
   * @param {string} data.filename 文件名,包括文件后缀
   * @returns
   */
  static upload(data: FormData): Promise<IFileResponse> {
    return Http.postForm('/m.api/admin.file/upload', data);
  }

  /**
   * 批量下载开始,同一个用户同时只能有一个批量下载在进行
   * @param {FormData} data
   * @param {string} data.type 下载类型:media-资源 folder-文件夹
   * @param {number} data.id
   * @returns
   */
  @apiPermission(['sys:file:init_batch_download'], '')
  static initBatchDownload(data: any): Promise<IFileResponse> {
    return Http.post('/m.api/admin.file/initBatchDownload', data);
  }

  /**
   * 用户的批量下载记录
   * @param {FormData} data
   * @param {string} data.type 下载类型:media-资源 folder-文件夹
   * @param {number} data.id
   * @returns
   */
  static selfBatchDownloadList(data: any): Promise<IPage<any>> {
    return Http.post('/m.api/admin.file/selfBatchDownloadList', {
      queryDTO: data,
    });
  }

  /**
   * 分享的批量下载记录
   * @param {FormData} data
   * @param {string} data.shareId 分享ID
   * @param {number} data.id
   * @returns
   */
  static shareBatchDownloadList(data: any): Promise<IPage<any>> {
    return Http.post('/m.api/admin.file/shareBatchDownloadList', {
      queryDTO: data,
    });
  }

  /**
   * 边缘上传路径PUT
   * @param {string} data 文件名，包括文件名后缀
   * @returns
   */
  static uploadPath(data: string): Promise<FileMargin> {
    return Http.post('/m.api/admin.file/uploadPath', {
      filename: data,
    });
  }

  /**
   * 批量下载单条查询进度（可轮询）
   * @param {string} data uuid
   * @returns
   */
  static percentInitBatchDownload(data: string): Promise<IPercentInitBatchDownload> {
    const d: IUuid = {
      uuid: data,
    };
    return Http.post('/m.api/admin.file/percentInitBatchDownload', d);
  }

  /**
   * 批量下载开始,返回UUID（分享）
   * @param {string} data uuid
   * @returns
   */
  static initBatchDownloadShare(data: any): Promise<any> {
    return Http.post('/m.api/admin.file/initBatchDownloadShare', data);
  }
}
