import admin from './admin';
import department from './department';
import file from './file';
import folder from './folder';
import media from './media';
import mediaDraft from './media-draft';
import mediaExtra from './media-extra';
import share from './share';
import statistics from './statistics';
import tag from './tag';
import task from './task';
import base from './base';
import operationLog from './operation-log';

export default {
  admin,
  department,
  file,
  folder,
  media,
  mediaDraft,
  mediaExtra,
  share,
  statistics,
  tag,
  task,
  base,
  operationLog,
};
