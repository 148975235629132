import Http from './http';
import { apiPermission } from '@/decorator/api-permission';
import { IPage } from '@/types/api/common';

export default class Media {
  /**
   * 查询media列表
   * @returns
   */
  @apiPermission(['media:media:list'], {})
  static list(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/list', data);
  }

  /**
   * 查询media详情
   * @returns
   */
  @apiPermission(['media:media:detail'], {})
  static detail(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/detail', data);
  }

  /**
   * Media日志
   * @returns
   */
  @apiPermission(['media:media:log_list'], {})
  static getLogById(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/getLogById', data);
  }

  /**
   * 重命名media
   * @returns
   */
  @apiPermission(['media:media:rename'], '')
  static rename(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/rename', data);
  }

  /**
   * 复制Media-单个 复制到组
   * @returns
   */
  @apiPermission(['media:media:copy'], '')
  static copySingle(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/copySingle', data);
  }

  /**
   * 移动Media文件夹 移动到组
   * @returns
   */
  @apiPermission(['media:media:move'], '')
  static move(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/move', data);
  }

  // ******回收站*********

  /**
   * 回收站彻底删除-批量
   * @returns
   */
  @apiPermission(['media:media:recycle_delete'], [])
  static deleteBatch(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/deleteBatch', data);
  }

  /**
   * 回收站彻底删除-单个
   * @returns
   */
  @apiPermission(['media:media:recycle_delete'], '')
  static deleteSingle(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/deleteSingle', data);
  }

  /**
   * 回收站还原-批量
   * @returns
   */
  @apiPermission(['media:media:recover'], [])
  static recoverBatch(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/recoverBatch', data);
  }

  /**
   * 回收站还原-单个
   * @returns
   */
  @apiPermission(['media:media:recover'], '')
  static recoverSingle(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/recoverSingle', data);
  }

  /**
   * 移入回收站-批量
   * @returns
   */
  @apiPermission(['media:media:recycle'], [])
  static recycleBatch(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/recycleBatch', data);
  }

  /**
   * 移入回收站-单个
   * @returns
   */
  @apiPermission(['media:media:recycle'], '')
  static recycleSingle(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/recycleSingle', data);
  }

  /**
   * 回收站列表
   * @returns
   */
  @apiPermission(['media:media:recycle_list'], {})
  static recycleList(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/recycleList', data);
  }

  /**
   * 查询media详情（分享）
   * @returns
   */
  static detailShare(data: any): Promise<any> {
    return Http.post('/m.api/admin.media/detailShare', data);
  }

  /**
   * 编辑单张素材下载
   * @returns
   */
  static markSingleDownload(data: number): Promise<string> {
    return Http.post('/m.api/admin.media/markSingleDownload', {
      id: data,
    });
  }

  /**
   * Media排行榜
   * @returns
   */
  @apiPermission(['media:media:media_rank'], {})
  static mediaRank(data: any): Promise<IPage<any>> {
    return Http.post('/m.api/admin.media/mediaRank', {
      queryDTO: data,
    });
  }
}
