import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
} from 'vue-router';
import { baseRoutes, extendRoutes, whiteRoute } from './routes';
import { useAuthStore } from '@/store/modules/auth';
import { addRoutesToSystem } from '@/utils/route';

const router: Router = createRouter({
  // 路由使用history模式，通过process.env.VUE_APP_PUBLIC_PATH配置二级路由
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes: [...baseRoutes, ...extendRoutes],
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> => {
    const authRoutes = useAuthStore().getAuthRoutes;
    const addRoutes = addRoutesToSystem(authRoutes);

    if (to.path === '/') {
      if (useAuthStore().getToken) {
        if (addRoutes) {
          next({ ...to, replace: true });
        } else {
          next();
        }
      } else {
        next({ path: '/login' });
      }
    } else if (whiteRoute.includes(to.path)) {
      next();
    } else if (addRoutes) {
      next({ ...to, replace: true });
    } else {
      next();
    }
  },
);

router.afterEach((): void => {
  window.scrollTo(0, 0);
});

export default router;
