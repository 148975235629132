<template>
  <t-config-provider>
    <router-view :key="route.fullPath" />
  </t-config-provider>
</template>

<script setup lang="ts">
import api from '@/api/index';
import { useMenuStatistics } from '@/hooks/use-menu-statistics';

import { useRoute } from 'vue-router';
const route = useRoute();

const { init } = useMenuStatistics();

onMounted(() => {
  // 获取用户信息
  api.admin.getUserInfo1().then(() => {
    init();
  });
});
</script>

<style scoped lang="scss"></style>
