import { DirectiveBinding } from 'vue';
import { useAuthStore } from '@/store/modules/auth';
import { checkBtnPermission } from '@/utils/permission';

/**
 * @Description: 权限验证自定义指令
 * 使用: v-permission="['sys']"
 */
const permissionDirective = {
  mounted(el: Element, binding: DirectiveBinding): void {
    // 从 binding.value 中获取所需的权限信息
    const permission: string[] = binding.value;
    if (!permission.length) return;

    // 判断是否有权限，没有权限则从 DOM 中移除元素
    const hasPermission: boolean = checkBtnPermission(permission, getAuthBtnPermissions());
    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
};

/**
 * 获取用户被授予的按钮权限
 */
const getAuthBtnPermissions = (): string[] => {
  const authStore = useAuthStore();
  return authStore.getAuthBtnPermissions;
};

export default permissionDirective;
