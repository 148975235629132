import { createApp } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';
import App from './App.vue';
import store from './store';
import i18n from './locales';
import router from './router';
import './styles/index.scss';
import directives from './directives/index';

window.ResizeObserver = ResizeObserver;

import TDesign from 'tdesign-vue-next';
// 引入组件库的少量全局样式变量
import 'tdesign-vue-next/es/style/index.css';
import '@/styles/theme/theme.css';

const app = createApp(App);

// 注册全局Icon组件
import '@/assets/iconfont/iconfont.js';
import compIconFont from '@/components/iconfont/index.vue';
app.component('icon-font', compIconFont);

app.use(TDesign);

app.use(store);
app.use(i18n);
app.use(router);
app.use(directives);

app.mount('#app');
