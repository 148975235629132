import axios, { AxiosResponse, AxiosError } from 'axios';
import { MessagePlugin } from 'tdesign-vue-next';
import { downloadFileBlob } from '@/utils/util.ts';
import { IResponse } from '@/types/api/common';
import { useAuthStore } from '@/store/modules/auth';
import { useLogoutState } from '@/hooks/system/use-logout';
import { debounce } from 'lodash';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  timeout: 180000,
  headers: { 'Content-Type': 'application/json' },
});

const logout = debounce((msg) => {
  MessagePlugin.error(msg);
  useLogoutState().logout();
}, 500);

// 请求拦截器
http.interceptors.request.use(
  async (config) => {
    const authStore = useAuthStore();
    config.headers['ADMINTOKEN'] = authStore.getToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 响应拦截器
http.interceptors.response.use(
  async (response: AxiosResponse<IResponse<any>>): Promise<IResponse<any> | any> => {
    const responseData: IResponse<any> = response.data;

    // 如果是文件，自动下载
    if (
      response.data instanceof Blob ||
      (response.headers['content-disposition'] &&
        response.headers['content-disposition'].indexOf('attachment') > -1)
    ) {
      const disposition = response.headers['content-disposition'];
      if (disposition) {
        const filename = disposition.split('filename=')[1];
        downloadFileBlob(response.data as any, filename);
        return Promise.resolve(response);
      }
    }

    // 成功正常返回数据
    if (responseData.errno === 200) {
      // 这里返回的是 IResponse<T> 中的 T
      return Promise.resolve(responseData.data);
    }

    // token过期处理
    if ([10006].includes(responseData.errno)) {
      // window.location.reload();

      // @ts-ignore 检测到未登录，是否不做处理直接返回，默认为 false
      if (!response.config['notAuthLogin']) {
        logout(responseData.errmsg);
      }

      return Promise.reject(responseData);
    }

    // @ts-ignore 是否隐藏错误提示，默认为 false
    if (!response.config['hideErrorMsg']) {
      MessagePlugin.error(responseData.errmsg || '服务端返回错误');
    }

    return Promise.reject(responseData);
  },
  (error: AxiosError) => {
    MessagePlugin.error(`接口请求异常: ${error.response?.statusText || '未知错误'}`);
    return Promise.reject(error);
  },
);

export default http;
