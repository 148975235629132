import { useAuthStore } from '@/store/modules/auth';
import { hasPermission } from '@/utils/permission';

/**
 * 获取用户的所有权限
 * @returns
 */
const getAllPermissions = (): string[] => {
  const authStore = useAuthStore();
  return authStore.getAllPermissions;
};

/**
 * 接口权限校验装饰器
 * @param {string} permission 接口的权限点
 * @param defaultValue 默认返回值，同接口返回data数据类型保持一致
 * @returns
 */
export const apiPermission = (permission: string[], defaultValue: any = null) => {
  console.info(`进入接口权限校验`, permission, defaultValue);

  /**
   * @param target 类本身
   * @param property 装饰的属性名
   * @param descriptor 属性的描述对象
   */
  return (_target: any, property: any, descriptor: any) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const bool: boolean = hasPermission(permission, getAllPermissions());
      if (bool) {
        console.log(`接口权限校验通过, 执行原方法 ${property}`);
        return originalMethod.apply(this, args);
      } else {
        console.log(`接口权限校验不通过, 返回默认值 ${property}`);
        return Promise.resolve(defaultValue);
      }
    };

    return descriptor;
  };
};
