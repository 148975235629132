import Http from './http';
import { IExtra } from '@/types/api/media-extra';

export default class MediaExtra {
  /**
   * @param data
   * @returns
   */
  static extraList(data: {}): Promise<IExtra[]> {
    return Http.post('/m.api/admin.media_extra/list', data);
  }

  /**
   * 通过值获取筛选项
   * @param data
   * @returns
   */
  static selectByValues(data: string): Promise<string[]> {
    return Http.post('/m.api/admin.media_extra/selectByValues', {
      key: data,
    });
  }
}
