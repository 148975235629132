import {App} from "vue";
import permission from './modules/permission';

// 自定义指令
const directives: Record<string, any> = {
    permission,
};

// 批量注册指令
export default {
  install(app: App): void {
    Object.keys(directives).forEach((key: string): void => {
      app.directive(key, directives[key]);
    });
  },
};
