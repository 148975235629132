import Http from './http';
import { apiPermission } from '@/decorator/api-permission';
import { IId } from '@/types/api/common';

export default class Folder {
  /**
   * 撤销删除文件夹
   * @returns
   */
  static cancelDelete(data: IId): Promise<string> {
    return Http.post('/m.api/admin.folder/cancelDelete', data);
  }

  /**
   * 查询文件夹子列表
   * @returns
   */
  static childList(data: any): Promise<any> {
    return Http.post('/m.api/admin.folder/childList', data);
  }

  /**
   * 创建文件夹
   * @returns
   */
  @apiPermission(['folder:folder:create'], '')
  static create(data: any): Promise<any> {
    return Http.post('/m.api/admin.folder/create', data);
  }

  /**
   * 删除文件夹
   * @returns
   */
  @apiPermission(['folder:folder:delete'], '')
  static delete(data: any): Promise<any> {
    return Http.post('/m.api/admin.folder/delete', data);
  }

  /**
   * 文件夹放入回收站
   * @returns
   */
  @apiPermission(['folder:folder:recycle'], '')
  static recycle(data: any): Promise<any> {
    return Http.post('/m.api/admin.folder/recycle', data);
  }

  /**
   * 查询文件夹详情
   * @returns
   */
  @apiPermission(['folder:folder:detail'], {})
  static detail(data: any): Promise<any> {
    return Http.post('/m.api/admin.folder/detail', data);
  }

  /**
   * 编辑文件夹
   * @returns
   */
  @apiPermission(['folder:folder:edit'], '')
  static edit(data: any): Promise<any> {
    return Http.post('/m.api/admin.folder/edit', data);
  }

  /**
   * 查询文件夹列表
   * @returns
   */
  @apiPermission(['folder:folder:list'], {})
  static list(data: any): Promise<any> {
    return Http.post('/m.api/admin.folder/list', data);
  }
  /**
   * 查询一级文件夹
   * @returns
   */
  static firstFolderKeys(data: any): Promise<any> {
    return Http.post('/m.api/admin.folder/firstFolderKeys', data);
  }

  /**
   * 合并查询列表
   * @returns
   */
  @apiPermission(['folder:folder:mergePage'], {})
  static mergePage(data: any): Promise<any> {
    return Http.post('/m.api/admin.folder/mergePage', {
      mergeQueryDTO: data,
    });
  }

  /**
   * 合并查询列表（分享）
   * @returns
   */
  static mergePageShare(data: any, password: string): Promise<any> {
    return Http.post('/m.api/admin.folder/mergePageShare', {
      mergeQueryDTO: data,
      password: password,
    });
  }
}
