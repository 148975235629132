import { RouteRecordRaw } from 'vue-router';
import { hasPermission } from './permission';
import router from '@/router/index';
import { route404 } from '@/router/routes';
const modulesViews = import.meta.glob('@/views/**/*.vue');
const modulesLayouts = import.meta.glob('@/layouts/*/*.vue');

export const componentString2Fn = (list: any) => {
  list.forEach((item: any) => {
    if (item.component) {
      item.component = () => modulesViews[`/src/views/${item.component}.vue`];
    }
    if (Array.isArray(item.children) && item.children.length) {
      componentString2Fn(item.children);
    }
  });
};

/**
 * 缓存的路由数据，没有存储到 () => import('') 组件
 * 需要手动处理下
 * @param list
 */
export const componentString2Fn1 = (list: RouteRecordRaw[]) => {
  list.forEach((item: RouteRecordRaw) => {
    if (item?.meta?.component) {
      const comp = item?.meta?.component as string;
      if (comp.indexOf('@/layouts') !== -1) {
        item.component = modulesLayouts[comp.replace('@', '/src')];
      } else {
        item.component = modulesViews[comp.replace('@', '/src')];
      }
    }
    if (Array.isArray(item.children) && item.children.length) {
      componentString2Fn1(item.children);
    }
  });
};

/**
 * 过滤权限，拿到权限通过的路由数据
 * @param {RouteRecordRaw[]} permissionRoutes 需要授权校验的路由
 * @param {string[]} authPermissions 授权的权限（系统授权的所有权限）
 * @returns {RouteRecordRaw[]} routes
 */
export const filterPermissionRoutes = (
  permissionRoutes: RouteRecordRaw[],
  authPermissions: string[],
): RouteRecordRaw[] => {
  // 如果权限数组包含*，则返回所有路由
  if (authPermissions.includes('*')) {
    return permissionRoutes;
  }

  // 权限通过的路由
  let authRoutes = [] as RouteRecordRaw[];

  permissionRoutes.forEach((item: RouteRecordRaw) => {
    // 获取配置的权限
    const perms = (item?.meta?.perms || ['*']) as string[];
    // 默认有权限
    const defaultPermissionTrue = !Array.isArray(perms) || perms.includes('*');

    // 授权有权限
    const authPermissionTrue = hasPermission(perms, authPermissions);

    if (defaultPermissionTrue || authPermissionTrue) {
      let children = [] as RouteRecordRaw[];
      // 存在子路由
      if (Array.isArray(item.children) && item.children.length) {
        children = filterPermissionRoutes(item.children, authPermissions);
      }

      // 添加到权限路由
      authRoutes.push({
        ...item,
        children,
      });
    }
  });

  return authRoutes;
};

/**
 * 添加路由到系统中
 * @param {RouteRecordRaw[]} routes 权限路由
 */
export const addRoutesToSystem = (routes: RouteRecordRaw[]) => {
  // 如果系统路由中存在404页面，即权限路由已经处理过，无需再处理
  const has404 = router.hasRoute('404');
  if (has404) {
    return false;
  }

  componentString2Fn1(routes);

  // 添加权限路由
  routes.forEach((item: RouteRecordRaw) => {
    router.addRoute(item);
  });

  // 最后添加404页面
  router.addRoute(route404);

  return true;
};

/**
 * 移除权限路由
 */
export const removeRoutes = (): void => {
  router.hasRoute('root') && router.removeRoute('root');
  router.hasRoute('404') && router.removeRoute('404');
};
