export default {
  systemTitle: 'PVH DAM ADMIN',
  login: {
    username: '账号',
    password: '密码',
    login: '登录',
    usernamePlaceholder: '请输入账号',
    passwordPlaceholder: '请输入密码',
    usernameOrPasswordError: '账号或密码错误',
  }
}
