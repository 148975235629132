/**
 * 下载二进制文件
 * @param {Blob} blob
 * @param {string} filename
 * @return void
 */
export const downloadFileBlob = (blob: Blob, filename: string): void => {
  const newBlob = new Blob([blob]);
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(newBlob);
  downloadElement.href = href;
  downloadElement.download = decodeURIComponent(filename);
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
};

/**
 * 判断两个数组是否存在交集
 * @param {Array<any>} arr1
 * @param {Array<any>} arr2
 * @return boolean
 */
export const arrUnite = (arr1: any[], arr2: any[]): boolean => {
  return arr1.some((item) => arr2.includes(item));
};

/**
 * 判断文件类型
 */
export function fileType(url: string) {
  if (url.endsWith('.pdf')) {
    return 'home-pdf';
  }
  if (url.endsWith('.doc') || url.endsWith('.docx')) {
    return 'home-doc';
  }
  if (url.endsWith('.xls') || url.endsWith('.xlsx')) {
    return 'home-xls';
  }
  if (url.endsWith('.ppt') || url.endsWith('.pptx')) {
    return 'home-pdf';
  }
}

/**
 * 包含高亮
 */
export const grayText = (txt: string, regValue: any) => {
  const blueText = txt.replace(
    new RegExp(`(${regValue})`, 'ig'),
    '<span style="color: #6c5dd3">$1</span>',
  );
  return blueText;
};

/**
 * 交集高亮
 */
export function highlightIntersection(string1: string, string2: string) {
  // 找到两个字符串的最长公共子串
  function longestCommonSubstring(str1: string, str2: string) {
    const m = str1.length;
    const n = str2.length;
    let maxLen = 0;
    let endIndex = 0;
    const lcsMatrix = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        if (str1[i - 1] === str2[j - 1]) {
          lcsMatrix[i][j] = lcsMatrix[i - 1][j - 1] + 1;
          if (lcsMatrix[i][j] > maxLen) {
            maxLen = lcsMatrix[i][j];
            endIndex = i - 1;
          }
        }
      }
    }

    return str1.substring(endIndex - maxLen + 1, endIndex + 1);
  }

  const commonSubstring = longestCommonSubstring(string1, string2);

  // 使用正则表达式高亮显示公共子串
  if (commonSubstring) {
    const regex = new RegExp(commonSubstring, 'ig');
    return string2.replace(regex, `<span style="color: #6c5dd3">${commonSubstring}</span>`);
  }

  return string2;
}

// 判断元素中是否有某个值
export function containsElement(val: string, arr: any) {
  return arr.indexOf(val) !== -1;
}

/**
 * 生成随机数：仅中英文和数字，指定生成长度
 */
export function randomString(len: number): string {
  len = len || 32;
  const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const maxPos = $chars.length;
  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }

  return pwd;
}

/**
 * 复制文本
 * @param {*} text
 */
export function copyText(text: string) {
  if (navigator.clipboard) {
    // @ts-ignore
    copyText = (text: string) => {
      navigator.clipboard.writeText(text);
    };
  } else {
    // @ts-ignore
    copyText = (text: string) => {
      const input = document.createElement('input');
      input.setAttribute('value', text);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    };
  }

  copyText(text);
}

/**
 * 获取文件夹名称
 * @param {*} text
 */
export function changeFolderName(name: string): string {
  var newName = name.substring(name.lastIndexOf('/') + 1);
  return newName;
}

/**
 * 将字节（Byte）转换成KB（千字节）或MB（兆字节）
 * @param bytes
 * @returns
 */
export function convertBytes(bytes: number): string {
  if (bytes >= 1048576) {
    // 大于等于1MB，转换成MB
    const mb = bytes / 1048576;
    return mb.toFixed(2).replace(/\.0+$/, '') + ' MB'; // 去除尾随零并添加单位
  } else {
    // 小于1MB，转换成KB
    const kb = bytes / 1024;
    return kb.toFixed(2).replace(/\.0+$/, '') + ' KB'; // 去除尾随零并添加单位
  }
}

/**
 * 数字转换
 * @param num
 * @returns
 */
export function formatNumber(num: number) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
}

/**
 * 根据指定字段和顺序对数组进行排序
 * @param {Array} data - 原对象数组
 * @param {string} field - 需要排序的字段
 * @param {Array} order - 排序顺序数组
 * @returns {Array} 排序后的新数组
 */
export function sortByFieldOrder<T>(data: T[], field: keyof T, order: string[]) {
  // 创建一个映射对象，快速查找每个字段值的排序优先级
  const orderMap = {} as any;
  order.forEach((value, index) => {
    orderMap[value] = index;
  });

  // 使用 sort 方法，根据字段值的优先级进行排序
  return data.slice().sort((a, b) => {
    const aOrder = orderMap[a[field]];
    const bOrder = orderMap[b[field]];
    return aOrder - bOrder;
  });
}

/**
 * base64转file文件
 * @param base64
 * @param fileName
 * @returns
 */
export function base64ToFile(base64: string, fileName: string) {
  let arr = base64.split(',');
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: 'image/png' });
}

/**
 * 下载文件
 * @param url
 * @param filename
 */
export function downloadFromUrl(url: string, filename?: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || '';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * 检测当前运行环境是在移动端还是在PC端
 */
export function isMobile(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 根据 userAgent 字符串中的关键字来判断是否为移动设备
  const mobileKeywords = [
    'Android',
    'webOS',
    'iPhone',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone',
    'Opera Mini',
    'IEMobile',
    'Silk',
  ];

  // 检查 userAgent 中是否包含上述任何一个关键词
  return mobileKeywords.some((keyword) => userAgent.indexOf(keyword) > -1);
}

/**
 * 提取不带后缀的文件名和文件后缀
 * @param str
 * @returns
 */
export function extractFileNameAndExtension(str: string) {
  // 正则表达式用于匹配文件名和后缀
  const regex = /^(.+?)\.([^.]+)$/;
  const match = str.match(regex);

  if (match) {
    // 提取文件名和后缀
    const fileName = match[1];
    const extension = match[2];

    return {
      fileName: fileName,
      extension: extension,
    };
  } else {
    // 如果不匹配，则返回null或抛出错误
    return null;
  }
}
