import { RouteRecordRaw } from 'vue-router';

/**
 * 基础路由
 */
export const baseRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: '首页',
    },
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search/index.vue'),
    meta: {
      title: '搜索结果页',
    },
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('@/views/share/index.vue'),
    meta: {
      title: '分享页',
    },
  },
  {
    path: '/share-detail',
    name: 'share-detail',
    component: () => import('@/views/share/detail.vue'),
    meta: {
      title: '分享详情',
    },
  },
  {
    path: '/preview-h5',
    name: 'preview-h5',
    component: () => import('@/views/office-preview-h5/index.vue'),
    meta: {
      title: '文件预览',
    },
  },
];

export const route404: RouteRecordRaw = {
  path: '/:pathMatch(.*)*',
  name: '404',
  component: () => import('@/views/auth/404.vue'),
  meta: {
    title: '404',
  },
};

/**
 * 扩展路由
 */
export const extendRoutes: RouteRecordRaw[] = [
  {
    path: '/extend',
    redirect: '/extend/message',
    component: () => import('@/layouts/base/index.vue'),
    meta: {
      component: '@/layouts/base/index.vue',
      title: 'PVH',
      perms: ['*'],
    },
    children: [
      {
        path: 'log',
        name: 'log',
        component: () => import('@/views/system/log/index.vue'),
        meta: {
          component: '@/views/system/log/index.vue',
          title: '日志',
          icon: 'log',
          perms: ['*'],
        },
      },
      {
        path: 'message',
        name: 'message',
        component: () => import('@/views/system/message/index.vue'),
        meta: {
          component: '@/views/system/message/index.vue',
          title: '消息',
          icon: 'message',
          perms: ['*'],
        },
      },
    ],
  },
];

/**
 * 权限路由
 * meta中数据说明:
 *   title: 菜单名称
 *   component: 同RouteRecordRaw中的component导入路径保持一致，路由做缓存时，component会丢失，故需要手动处理下
 *   icon: 菜单图标
 *   perms: 权限标识
 *   hidden: 是否隐藏
 *   active: 搭配hidden使用，路由作为左侧菜单栏时，被隐藏并且激活时，显示该菜单
 *   cache: 是否做 router-view 缓存
 */
export const permissionRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'root',
    redirect: 'dashboard',
    component: () => import('@/layouts/base/index.vue'),
    meta: {
      component: '@/layouts/base/index.vue',
      title: 'PVH',
      icon: 'home',
      perms: ['*'],
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
          component: '@/views/dashboard/index.vue',
          cache: true,
          title: '数据统计',
          icon: 'data-count',
          perms: ['*'],
        },
      },
      {
        path: 'material',
        name: 'material',
        redirect: 'material/group',
        meta: {
          cache: true,
          title: '素材库',
          icon: 'material',
          perms: [
            'media:media:copy',
            'media:media:detail',
            'media:media:list',
            'media:media:move',
            'media:media:rename',
            'folder:folder:copy',
            'folder:folder:create',
            'folder:folder:delete',
            'folder:folder:detail',
            'folder:folder:edit',
            'folder:folder:list',
            'folder:folder:mergePage',
            'folder:folder:move',
            'folder:folder:recycle',
          ],
        },
        children: [
          {
            path: 'group',
            name: 'material-group',
            component: () => import('@/views/material/group/index.vue'),
            meta: {
              component: '@/views/material/group/index.vue',
              cache: true,
              title: '素材组',
              countField: 'materialGroup',
              perms: [
                'folder:folder:copy',
                'folder:folder:create',
                'folder:folder:delete',
                'folder:folder:detail',
                'folder:folder:edit',
                'folder:folder:list',
                'folder:folder:mergePage',
                'folder:folder:move',
                'folder:folder:recycle',
              ],
            },
          },

          {
            path: 'group-detail',
            name: 'group-detail',
            component: () => import('@/views/material/group/group-detail.vue'),
            meta: {
              component: '@/views/material/group/group-detail.vue',
              cache: true,
              hidden: true,
              active: '/material/group',
              title: '素材组详情',
              perms: [
                'folder:folder:copy',
                'folder:folder:create',
                'folder:folder:delete',
                'folder:folder:detail',
                'folder:folder:edit',
                'folder:folder:list',
                'folder:folder:mergePage',
                'folder:folder:move',
                'folder:folder:recycle',
              ],
            },
          },

          {
            path: 'picture',
            name: 'picture',
            component: () => import('@/views/material/picture/index.vue'),
            meta: {
              component: '@/views/material/picture/index.vue',
              cache: true,
              title: '图片',
              countField: 'materialPicture',
              perms: [
                'media:media:copy',
                'media:media:detail',
                'media:media:list',
                'media:media:move',
                'media:media:rename',
              ],
            },
          },

          {
            path: 'picture-detail',
            name: 'picture-detail',
            component: () => import('@/views/material/picture/picture-detail.vue'),
            meta: {
              component: '@/views/material/picture/picture-detail.vue',
              cache: true,
              hidden: true,
              active: '/material/picture',
              title: '图片详情',
              perms: [
                'media:media:copy',
                'media:media:detail',
                'media:media:list',
                'media:media:move',
                'media:media:rename',
              ],
            },
          },

          {
            path: 'video',
            name: 'material-video',
            component: () => import('@/views/material/video/index.vue'),
            meta: {
              component: '@/views/material/video/index.vue',
              cache: true,
              title: '视频',
              countField: 'materialVideo',
              perms: [
                'media:media:copy',
                'media:media:detail',
                'media:media:list',
                'media:media:move',
                'media:media:rename',
              ],
            },
          },
          {
            path: 'video-detail',
            name: 'video-detail',
            component: () => import('@/views/material/video/video-detail.vue'),
            meta: {
              component: '@/views/material/video/video-detail.vue',
              cache: true,
              hidden: true,
              active: '/material/video',
              title: '视频详情',
              perms: [
                'media:media:copy',
                'media:media:detail',
                'media:media:list',
                'media:media:move',
                'media:media:rename',
              ],
            },
          },
          {
            path: 'document',
            name: 'material-document',
            component: () => import('@/views/material/document/index.vue'),
            meta: {
              component: '@/views/material/document/index.vue',
              cache: true,
              title: '文档',
              countField: 'materialOffice',
              perms: [
                'media:media:copy',
                'media:media:detail',
                'media:media:list',
                'media:media:move',
                'media:media:rename',
              ],
            },
          },
          {
            path: 'document-detail',
            name: 'document-detail',
            component: () => import('@/views/material/document/document-detail.vue'),
            meta: {
              component: '@/views/material/document/document-detail.vue',
              cache: true,
              hidden: true,
              active: '/material/document',
              title: '文档详情',
              perms: [
                'media:media:copy',
                'media:media:detail',
                'media:media:list',
                'media:media:move',
                'media:media:rename',
              ],
            },
          },
        ],
      },
      {
        path: 'approval',
        name: 'approval',
        redirect: 'approval/audit',
        meta: {
          title: '审批管理',
          icon: 'approval',
          perms: [
            'media:media_draft:approve',
            'media:media_draft:approve',
            'media:media_draft:approve_list',
            'media:media_draft:revocation',
            'media:media_draft:submit',
          ],
        },
        children: [
          {
            path: 'audit',
            name: 'approval-audit',
            component: () => import('@/views/approval/audit/index.vue'),
            meta: {
              component: '@/views/approval/audit/index.vue',
              title: '我审批的',
              icon: 'setting',
              countField: 'approvalMy',
              perms: [
                'media:media_draft:approve',
                'media:media_draft:approve',
                'media:media_draft:approve_list',
                'media:media_draft:revocation',
                'media:media_draft:submit',
              ],
            },
          },
          {
            path: 'apply',
            name: 'approval-apply',
            component: () => import('@/views/approval/apply/index.vue'),
            meta: {
              component: '@/views/approval/apply/index.vue',
              title: '待审批的',
              icon: 'setting',
              countField: 'approvalDoing',
              perms: [
                'media:media_draft:approve',
                'media:media_draft:approve',
                'media:media_draft:approve_list',
                'media:media_draft:revocation',
                'media:media_draft:submit',
              ],
            },
          },
          {
            path: 'detail',
            name: 'approval-detail',
            component: () => import('@/views/approval/detail/index.vue'),
            meta: {
              component: '@/views/approval/detail/index.vue',
              title: '详情页',
              icon: 'setting',
              hidden: true,
              active: '/approval/*',
              perms: [
                'media:media_draft:approve',
                'media:media_draft:approve',
                'media:media_draft:approve_list',
                'media:media_draft:revocation',
                'media:media_draft:submit',
              ],
            },
          },
        ],
      },
      {
        path: 'task',
        name: 'task',
        redirect: 'task/execute',
        meta: {
          title: '任务管理',
          icon: 'task',
          perms: [
            'task:task:create',
            'task:task:delete',
            'task:task:detail',
            'task:task:detailauditor',
            'task:task:edit',
            'task:task:list',
            'task:task:listmy',
            'task:task:reminder',
          ],
        },
        children: [
          {
            path: 'execute',
            name: 'task-execute',
            component: () => import('@/views/task/execute/index.vue'),
            meta: {
              component: '@/views/task/execute/index.vue',
              title: '我的任务',
              icon: 'setting',
              countField: 'taskMy',
              perms: ['task:task:list', 'task:task:detail', 'task:task:listmy'],
            },
          },
          {
            path: 'info',
            name: 'task-info',
            component: () => import('@/views/task/info/index.vue'),
            meta: {
              component: '@/views/task/info/index.vue',
              title: '任务详情',
              icon: 'setting',
              hidden: true,
              active: '/task/execute',
              perms: ['task:task:detail'],
            },
          },
          {
            path: 'assign',
            name: 'task-assign',
            component: () => import('@/views/task/assign/index.vue'),
            meta: {
              component: '@/views/task/assign/index.vue',
              title: '指派任务',
              icon: 'setting',
              countField: 'taskIssue',
              perms: [
                'task:task:create',
                'task:task:delete',
                'task:task:detail',
                'task:task:detailauditor',
                'task:task:edit',
                'task:task:list',
                'task:task:reminder',
              ],
            },
          },
        ],
      },
      {
        path: 'storage',
        name: 'storage',
        component: () => import('@/views/storage/index.vue'),
        meta: {
          component: '@/views/storage/index.vue',
          cache: true,
          title: '待入库',
          icon: 'stock-pending',
          perms: [
            'media:media_draft:create',
            'media:media_draft:delete',
            'media:media_draft:self_list',
            'media:media_draft:edit',
            'media:media_draft:export',
            'media:media_draft:log_list',
            'media:media_draft:recycle',
            'media:media_draft:revocation',
            'media:media_draft:submit',
          ],
        },
      },

      {
        path: 'storage-detail',
        name: 'storage-detail',
        component: () => import('@/views/storage/storage-detail.vue'),
        meta: {
          component: '@/views/storage/storage-detail.vue',
          cache: true,
          hidden: true,
          active: '/storage',
          title: '待入库详情',
          perms: [
            'media:media_draft:create',
            'media:media_draft:delete',
            'media:media_draft:self_list',
            'media:media_draft:edit',
            'media:media_draft:export',
            'media:media_draft:log_list',
            'media:media_draft:recycle',
            'media:media_draft:revocation',
            'media:media_draft:submit',
          ],
        },
      },

      {
        path: 'member',
        name: 'member',
        redirect: 'member/group',
        meta: {
          title: '成员管理',
          icon: 'user',
          perms: [
            'sys:admin:create',
            'sys:admin:delete',
            'sys:admin:edit',
            'sys:admin:editstatus',
            'sys:admin:grant',
            'sys:admin:grantfolders',
            'sys:admin:grantset',
            'sys:admin:import',
            'sys:admin:list',
            'sys:admin:permissionlist',
            'sys:admin:permissionset',
            'sys:admin:resetpassword',
            'sys:admin:sendDefaultPassword',
          ],
        },
        children: [
          {
            path: 'group',
            name: 'member-group',
            component: () => import('@/views/member/group/index.vue'),
            meta: {
              component: '@/views/member/group/index.vue',
              title: '用户组管理',
              icon: 'setting',
              perms: [
                'sys:admin:create',
                'sys:admin:delete',
                'sys:admin:edit',
                'sys:admin:editstatus',
                'sys:admin:grant',
                'sys:admin:grantfolders',
                'sys:admin:grantset',
                'sys:admin:import',
                'sys:admin:list',
                'sys:admin:permissionlist',
                'sys:admin:permissionset',
                'sys:admin:resetpassword',
                'sys:admin:sendDefaultPassword',
              ],
            },
          },
          {
            path: 'user',
            name: 'member-user',
            component: () => import('@/views/member/user/index.vue'),
            meta: {
              component: '@/views/member/user/index.vue',
              title: '用户管理',
              icon: 'setting',
              perms: [
                'sys:admin:create',
                'sys:admin:delete',
                'sys:admin:edit',
                'sys:admin:editstatus',
                'sys:admin:grant',
                'sys:admin:grantfolders',
                'sys:admin:grantset',
                'sys:admin:import',
                'sys:admin:list',
                'sys:admin:permissionlist',
                'sys:admin:permissionset',
                'sys:admin:resetpassword',
                'sys:admin:sendDefaultPassword',
              ],
            },
          },
        ],
      },
      {
        path: 'tag',
        name: 'tag',
        component: () => import('@/views/tag/index.vue'),
        meta: {
          component: '@/views/tag/index.vue',
          cache: true,
          title: '标签管理',
          icon: 'tag',
          perms: [
            'tag:tag:list',
            'tag:tag:detail',
            'tag:tag:downloadTags',
            'tag:tag:createPersonalTag',
            'tag:tag:editPersonalTag',
            'tag:tag:deletePersonalTag',
            'tag:tag:createEnterpriseTag',
            'tag:tag:deleteEnterpriseTag',
            'tag:tag:EnterpriseTag',
            'tag:tag:importEnterpriseTag',
            'tag:tag:importPersonalTag',
          ],
        },
      },
      {
        path: 'recycled',
        name: 'recycled',
        component: () => import('@/views/recycled/index.vue'),
        meta: {
          component: '@/views/recycled/index.vue',
          cache: true,
          title: '回收站',
          icon: 'recycle-bin',
          perms: [
            'media:media_draft:recover',
            'media:media_draft:recycle',
            'media:media_draft:recycle_list',
            'media:media_draft:recycle',
            'media:media:recycle_delete',
            'media:media:recover',
            'media:media:recycle',
            'media:media:recycle_list',
          ],
        },
      },
    ],
  },
];

/**
 * 白名单路由，不需要鉴权
 */
export const whiteRoute: string[] = ['/login', '/404', '/share', '/preview-h5'];
