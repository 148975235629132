import Http from './http';
import { apiPermission } from '@/decorator/api-permission';
import { IId } from '@/types/api/common';
import {
  ICreateDepartmentRequest,
  IGrantRequest,
  IGrantSetRequest,
  IMoveRequest,
  IPermissionResponse,
  IPermissionSetResponse,
  IRenameRequest,
  IDepartmentTree,
  IDepartmentTreeAdmin,
} from '@/types/api/department';

export default class Department {
  /**
   * 创建
   * @returns
   */
  @apiPermission(['sys:department:create'], '')
  static create(data: ICreateDepartmentRequest): Promise<string> {
    return Http.post('/m.api/admin.department/create', data);
  }

  /**
   * 删除
   * @returns
   */
  @apiPermission(['sys:department:delete'], '')
  static delete(data: IId): Promise<string> {
    return Http.post('/m.api/admin.department/delete', data);
  }

  /**
   * 授权
   * @returns
   */
  @apiPermission(['sys:department:grant'], '')
  static grant(data: IGrantRequest): Promise<string> {
    return Http.post('/m.api/admin.department/grant', data);
  }

  /**
   * 授权（权限集）
   * @returns
   */
  @apiPermission(['sys:department:grantset'], '')
  static grantSet(data: IGrantSetRequest): Promise<string> {
    return Http.post('/m.api/admin.department/grantSet', data);
  }

  /**
   * 移动
   * @returns
   */
  @apiPermission(['sys:department:move'], '')
  static move(data: IMoveRequest): Promise<string> {
    return Http.post('/m.api/admin.department/move', data);
  }

  /**
   * 权限点
   * @returns
   */
  @apiPermission(['sys:department:permissionlist'], {})
  static permissionList(data: IId): Promise<IPermissionResponse> {
    return Http.post('/m.api/admin.department/permissionList', data);
  }

  /**
   * 权限集
   * @returns
   */
  @apiPermission(['sys:department:permissionset'], {})
  static permissionSet(data: IId): Promise<IPermissionSetResponse> {
    return Http.post('/m.api/admin.department/permissionSet', data);
  }

  /**
   * 重命名
   * @returns
   */
  @apiPermission(['sys:department:rename'], '')
  static rename(data: IRenameRequest): Promise<string> {
    return Http.post('/m.api/admin.department/rename', data);
  }

  /**
   * 部门树
   * @returns
   */
  static tree(): Promise<IDepartmentTree[]> {
    return Http.post('/m.api/admin.department/tree');
  }

  /**
   * 部门树（带成员）
   * @returns
   */
  static treeWithAdmin(): Promise<IDepartmentTreeAdmin[]> {
    return Http.post('/m.api/admin.department/treeWithAdmin');
  }

  /**
   * 获取授权数据
   * @returns
   */
  @apiPermission(['sys:department:grantfolders'], [])
  static getGrantFolders(data: { departmentId: number }): Promise<string[]> {
    return Http.post('/m.api/admin.department/getGrantFolders', data);
  }

  /**
   * 授权数据
   * @returns
   */
  @apiPermission(['sys:department:grantfolders'], '')
  static grantFolders(data: {
    targetDepartmentIds: number[];
    folderKeys: string[];
  }): Promise<string> {
    return Http.post('/m.api/admin.department/grantFolders', data);
  }
}
