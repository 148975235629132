import Http from './http';
import { apiPermission } from '@/decorator/api-permission';
import {
  IShareValidateDTO,
  IShareValidate,
  ICreateShareRequest,
  ICreate,
  ICreateResponse,
  IShareOpenInfo,
} from '@/types/api/share';

export default class Share {
  /**
   * 验证分享密码是否正确
   * @returns
   */
  static validate(data: IShareValidate): Promise<boolean> {
    const params: IShareValidateDTO = {
      shareValidateDTO: data,
    };
    return Http.post('/m.api/admin.share/validate', params);
  }

  /**
   * 创建分享
   * @returns
   */
  @apiPermission(['media:share:create'], {})
  static create(data: ICreate): Promise<ICreateResponse> {
    const params: ICreateShareRequest = {
      createDTO: data,
    };
    return Http.post('/m.api/admin.share/create', params);
  }

  /**
   * 查询分享列表
   * @returns
   */
  @apiPermission(['media:share:list'], {})
  static list(data: any): Promise<any> {
    return Http.post('/m.api/admin.share/list', data);
  }

  /**
   * 分享开放信息
   * @returns
   */
  static openInfo(data: string): Promise<IShareOpenInfo> {
    return Http.post('/m.api/admin.share/openInfo', {
      uuid: data,
    });
  }
}
