/**
 * 验证按钮权限
 * @param permission 当前按钮的权限
 * @param authPermissions 授权的权限（系统授权的所有权限）
 * @returns boolean
 */
export const checkBtnPermission = (permission: string[], authPermissions: string[]): boolean => {
  return hasPermission(permission, authPermissions);
};

/**
 * 权限验证
 * @param configPermissions 配置的权限（当前按钮或页面的权限）：['approval:list:create']
 * @param authPermissions 授权的权限（系统授权的所有权限）：['*', 'approval:list:create', '...']
 * @returns
 */
export function hasPermission(configPermissions: string[], authPermissions: string[]): boolean {
  // 将authPermissions数组转换为一个集合（Set），以便于快速查找。
  const authSet = new Set(authPermissions);

  // 如果authPermissions中包含'*'，则总是匹配
  if (authSet.has('*')) {
    return true;
  }

  /**
   * 辅助函数：根据权限字符串生成所有的可能模式
   * @example approval:list:create
   *         ['*', 'approval:*', 'approval:list:*', 'approval:list:create']
   * @param permission 权限字符串
   * @returns
   */
  function generatePatterns(permission: string) {
    const parts = permission.split(':');
    const patterns = [];
    for (let i = 0; i <= parts.length; i++) {
      patterns.push(parts.slice(0, i).join(':') + (i < parts.length ? ':*' : ''));
    }
    return patterns;
  }

  // 匹配权限
  for (let required of configPermissions) {
    const patterns = generatePatterns(required);
    for (let pattern of patterns) {
      if (authSet.has(pattern)) {
        return true;
      }
    }
  }

  return false;
}
