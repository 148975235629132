import Http from './http';
import { ILableValue } from '@/types/common';

/**
 * 基础接口
 */
export default class Base {
  /**
   * 获取枚举数组
   * @param {string} data ((@/utils/enum/)apiEnum.***)
   * @returns
   */
  static getEnum(data: string): Promise<ILableValue[]> {
    const params = {
      clazz: process.env.VUE_APP_ENUM_PREFIX + data,
      label: '',
      value: '',
    };
    return Http.post('/m.api/base/getEnum', params);
  }
}
